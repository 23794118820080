@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply antialiased text-base;
}

#root {
  @apply min-h-screen flex flex-col;
}

.content-container {
  @apply flex-grow pb-20 px-4 max-w-2xl mx-auto w-full;
}